import {ReactElement} from "react";
import {NftGrouping, NftOwnership} from "@devour/client";
import NftOwnershipImage from "@/components/NftOwnershipImage";

interface Props {
    ownership: NftOwnership;
    grouping: NftGrouping;
}

function GoFrensOwnershipRowInfo(props: Props): ReactElement {

    return (
        <div className="gofrens-ownership-row">
            <div className="gofrens-ownership-row_graphic-container">
                <NftOwnershipImage
                    className="gofrens-ownership-row_graphic"
                    alt="GoFrens Card Thumbnail"
                    ownership={props.ownership}
                />
            </div>
            <p>{props.grouping.name} #{props.ownership.nftId}</p>
        </div>
    );
}

export default GoFrensOwnershipRowInfo;
