import {ReactElement} from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import {NftOwnership} from "@devour/client";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import {getOpenSeaChainUrl} from "@/utils/parseChain";
import NftOwnershipImage from "@/components/NftOwnershipImage";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    ownership: NftOwnership;
}

function NftOwnershipCardModal(props: Props): ReactElement {

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.onClose}
            contentClassName="nft-ownership-card-modal"
        >
            <FrameModalHeader
                title="NFT Details"
                toggle={props.onClose}
            />

            <FrameModalBody className="nft-ownership-card-modal_body">
                <div className="nft-ownership-card-modal_body_thumb-con">
                    <NftOwnershipImage
                        ownership={props.ownership}
                        alt="GoFriends Card Thumbnail"
                        className="nft-ownership-card-modal_body_thumb-con_img"
                    />
                </div>

                <div className="nft-ownership-card-modal_body_details">
                    <div className="nft-ownership-card-modal_body_details_tracker-row">
                        <h6 className="nft-ownership-card-modal_body_details_tracker-row_name">
                            {props.ownership.tracker.name}
                        </h6>
                    </div>

                    <h3>
						#{props.ownership.nftId}
                    </h3>

                    <FrameButton
                        color="gray"
                        size="pill"
                        href={`https://opensea.io/assets/${getOpenSeaChainUrl(props.ownership.tracker.evmChain)}/${props.ownership.tracker.contractAddress}/${props.ownership.nftId}`}
                        className="nft-ownership-card-modal_body_details_btn"
                    >
						View on OpenSea
                    </FrameButton>
                </div>
            </FrameModalBody>
        </FrameOneModal>
    );
}

export default NftOwnershipCardModal;
