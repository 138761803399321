/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    NftTracker,
    NftTrackerFromJSON,
    NftTrackerFromJSONTyped,
    NftTrackerToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a map of ownership for the different Nft Groupings.
 * @export
 * @interface NftOwnership
 */
export interface NftOwnership {
    /**
     * 
     * @type {string}
     * @memberof NftOwnership
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NftOwnership
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof NftOwnership
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof NftOwnership
     */
    nftGroupingId?: string;
    /**
     * 
     * @type {string}
     * @memberof NftOwnership
     */
    nftTrackerId: string;
    /**
     * 
     * @type {string}
     * @memberof NftOwnership
     */
    registrySigningId: string;
    /**
     * 
     * @type {string}
     * @memberof NftOwnership
     */
    userId?: string;
    /**
     * 
     * @type {number}
     * @memberof NftOwnership
     */
    nftId: number;
    /**
     * 
     * @type {string}
     * @memberof NftOwnership
     */
    tokenUri?: string;
    /**
     * 
     * @type {string}
     * @memberof NftOwnership
     */
    tokenImageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof NftOwnership
     */
    nftGroupingIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof NftOwnership
     */
    nftTrackerIndex?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NftOwnership
     */
    isEligibleForCompetitions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NftOwnership
     */
    ofCurrentCommunity?: boolean;
    /**
     * 
     * @type {NftTracker}
     * @memberof NftOwnership
     */
    tracker?: NftTracker;
}

export function NftOwnershipFromJSON(json: any): NftOwnership {
    return NftOwnershipFromJSONTyped(json, false);
}

export function NftOwnershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftOwnership {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'nftGroupingId': !exists(json, 'nftGroupingId') ? undefined : json['nftGroupingId'],
        'nftTrackerId': json['nftTrackerId'],
        'registrySigningId': json['registrySigningId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'nftId': json['nftId'],
        'tokenUri': !exists(json, 'tokenUri') ? undefined : json['tokenUri'],
        'tokenImageUrl': !exists(json, 'tokenImageUrl') ? undefined : json['tokenImageUrl'],
        'nftGroupingIndex': !exists(json, 'nftGroupingIndex') ? undefined : json['nftGroupingIndex'],
        'nftTrackerIndex': !exists(json, 'nftTrackerIndex') ? undefined : json['nftTrackerIndex'],
        'isEligibleForCompetitions': !exists(json, 'isEligibleForCompetitions') ? undefined : json['isEligibleForCompetitions'],
        'ofCurrentCommunity': !exists(json, 'ofCurrentCommunity') ? undefined : json['ofCurrentCommunity'],
        'tracker': !exists(json, 'tracker') ? undefined : NftTrackerFromJSON(json['tracker']),
    };
}

export function NftOwnershipToJSON(value?: NftOwnership | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'nftGroupingId': value.nftGroupingId,
        'nftTrackerId': value.nftTrackerId,
        'registrySigningId': value.registrySigningId,
        'userId': value.userId,
        'nftId': value.nftId,
        'tokenUri': value.tokenUri,
        'tokenImageUrl': value.tokenImageUrl,
        'nftGroupingIndex': value.nftGroupingIndex,
        'nftTrackerIndex': value.nftTrackerIndex,
        'isEligibleForCompetitions': value.isEligibleForCompetitions,
        'ofCurrentCommunity': value.ofCurrentCommunity,
        'tracker': NftTrackerToJSON(value.tracker),
    };
}


