/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForceUpdateNftOwnershipResponse
 */
export interface ForceUpdateNftOwnershipResponse {
    /**
     * 
     * @type {number}
     * @memberof ForceUpdateNftOwnershipResponse
     */
    inserted: number;
    /**
     * 
     * @type {number}
     * @memberof ForceUpdateNftOwnershipResponse
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof ForceUpdateNftOwnershipResponse
     */
    existed: number;
    /**
     * 
     * @type {number}
     * @memberof ForceUpdateNftOwnershipResponse
     */
    skipped: number;
}

export function ForceUpdateNftOwnershipResponseFromJSON(json: any): ForceUpdateNftOwnershipResponse {
    return ForceUpdateNftOwnershipResponseFromJSONTyped(json, false);
}

export function ForceUpdateNftOwnershipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForceUpdateNftOwnershipResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inserted': json['inserted'],
        'deleted': json['deleted'],
        'existed': json['existed'],
        'skipped': json['skipped'],
    };
}

export function ForceUpdateNftOwnershipResponseToJSON(value?: ForceUpdateNftOwnershipResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inserted': value.inserted,
        'deleted': value.deleted,
        'existed': value.existed,
        'skipped': value.skipped,
    };
}


