import {ReactElement, useEffect, useState} from "react";
import {NftOwnership} from "@devour/client";
import {
    getNftInformationFromTokenUri,
    NftInformationFromTokenUriOutput,
} from "@/utils/getNftInformationFromTokenUri";

interface Props {
    ownership: NftOwnership;
    className?: string;
    alt?: string;
}

function NftOwnershipImage(props: Props): ReactElement {

    const [ipfs, setIpfs] = useState<NftInformationFromTokenUriOutput>();
    const imageSrc = props.ownership?.tokenImageUrl || ipfs?.imageBase64 || ipfs?.imageResolveUrl;

    useEffect(() => {
        // Use IPFS if image not cached
        if (!props.ownership?.tokenImageUrl) {
            void fetchIpfsInformation();
        }
    }, [props.ownership]);

    async function fetchIpfsInformation() {
        if (!props.ownership?.tokenUri) {
            return;
        }
        const res = await getNftInformationFromTokenUri(props.ownership?.tokenUri);
        setIpfs(res);
    }

    if (!imageSrc) {
        return null;
    }

    return (
        <img
            className={props.className}
            alt={props.alt}
            src={imageSrc}
        />
    );
}

export default NftOwnershipImage;