import {ReactElement, useEffect, useState} from "react";
import GoFrensHeader from "@/components/goFrens/GoFrensHeader";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import {NftOwnershipInformation, Token, UsersApi} from "@devour/client";
import {useGetUnauthenticatedNftGroupings} from "@/hooks/useGetUnauthenticatedNftGroupings";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GoFrensOtherCommunities from "@/components/goFrens/GoFrensOtherCommunities";
import GoFrensMyNfts from "@/components/goFrens/GoFrensMyNfts";
import DevourTopNav from "@/components/DevourTopNav";
import GoFrensHeaderNoRepCommunity from "@/components/goFrens/GoFrensHeaderNoRepCommunity";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import GoFrensCommunityHeader from "@/components/goFrens/GoFrensCommunityHeader";
import GoFrensCommunityLeaderBoard from "@/components/goFrens/GoFrensCommunityLeaderBoard";
import GoFrensTopLeaderboard from "@/components/goFrens/GoFrensTopLeaderboard";
import GoFrensTutorial from "@/components/GoFrensTutorial";
import {addError} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import Toast from "@/components/Toast";
import useThemePreference from "@/hooks/useThemePreference";

function GoFrensPage(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();

    const {
        data: nftOwnershipsForUserData,
        isLoading: isNftOwnershipsForUserDataLoading,
        refetch: refetchNftOwnerships,
    } = useGetNftOwnershipsForUser(fullToken as Token);

    const {data: unauthenticatedNftGroupingsData} = useGetUnauthenticatedNftGroupings(true);
    const {data: currentUser} = useGetUserProfile(fullToken);

    const [nftOwnershipResponse, setNftOwnershipResponse] = useState<NftOwnershipInformation>(undefined);
    const [showToast, setShowToast] = useState<boolean>(false);

    const { getThemedImageUrl } = useThemePreference();

    function handleToastDismissal() {
        setShowToast(false);
    }

    useEffect(() => {
        if (fullToken) {
            if (nftOwnershipsForUserData) {
                setNftOwnershipResponse(nftOwnershipsForUserData);
            }
        } else if (unauthenticatedNftGroupingsData) {
            setNftOwnershipResponse({
                userIds: [],
                nftOwnerships: [],
                nftTrackers: [],
                myGroupings: [],
                nftGroupings: unauthenticatedNftGroupingsData?.nftGroupings,
            });
        }
    }, [
        nftOwnershipsForUserData,
        unauthenticatedNftGroupingsData,
        fullToken?.id,
    ]);

    useEffect(() => {
        if (currentUser?.user?.isEjectedFromCommunity) {
            void handleUserEjection();
        }
    }, [currentUser?.user?.isEjectedFromCommunity]);

    async function handleUserEjection(): Promise<void> {
        try {
            await refetchNftOwnerships();
            await new UsersApi(getConfig(fullToken)).resetIsEjected();
            setShowToast(true);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function isNftOwned(): boolean {
        if (!fullToken) {
            return false;
        }
        if (isNftOwnershipsForUserDataLoading) {
            return true;
        }
        if (!nftOwnershipResponse?.nftOwnerships?.length) {
            return false;
        }
        return !(nftOwnershipResponse?.nftOwnerships?.length === 1 && !nftOwnershipResponse?.nftOwnerships[0].length);
    }

    function renderOtherCommunities(): ReactElement {
        return (
            <GoFrensOtherCommunities
                nftOwnershipResponse={nftOwnershipResponse}
                isNftOwnershipResponseLoading={isNftOwnershipsForUserDataLoading}
            />
        );
    }

    function renderHeader(): ReactElement {
        if (!fullToken || !isNftOwnershipsForUserDataLoading && (nftOwnershipResponse?.myGroupings.length === 0 ||
            !nftOwnershipResponse?.nftOwnerships?.[0].length)) {
            return (
                <GoFrensHeader
                    nftOwnershipResponse={nftOwnershipResponse}
                    isNftOwnershipResponseLoading={isNftOwnershipsForUserDataLoading}
                />
            );
        }

        if (fullToken && isNftOwnershipsForUserDataLoading) {
            return <div className="react-loading-skeleton gofrens-header-skeleton"/>;
        }

        if (currentUser?.user && !currentUser.user?.community) {
            return (
                <>
                    <GoFrensHeaderNoRepCommunity
                        nftOwnershipResponse={nftOwnershipResponse}
                        isNftOwnershipResponseLoading={isNftOwnershipsForUserDataLoading}
                    />
                </>
            );
        }

        if (currentUser?.user?.community) {
            return <GoFrensCommunityHeader/>;
        }

        return <div className="react-loading-skeleton gofrens-header-skeleton"/>;
    }

    function renderLeaderboards(): ReactElement {
        return (
            <>
                {fullToken &&
                    <div
                        className="gofrens_divider"
                    />
                }
                <GoFrensTopLeaderboard/>
            </>);
    }

    return (
        <>
            <Toast
                duration={10000}
                forceWidth="30rem"
                message="As you no longer hold an eligible NFT, you've been removed from your community. You can rejoin the same community when you gain another NFT."
                isOpen={showToast}
                variant="error"
                onDismiss={handleToastDismissal}
            />
            <DevourTopNav
                logoUrl={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/gofriends-logo.png`)}
            />
            <div className="gofrens">
                {renderHeader()}

                {fullToken && !isNftOwned() && renderOtherCommunities()}

                {currentUser?.user?.community && <GoFrensCommunityLeaderBoard/>}

                {/* {isNftOwned() && <GoFrensRewards/>} */}

                <div id="leaderboards">
                    {renderLeaderboards()}
                </div>

                {isNftOwned() &&
                    <>
                        <div className="gofrens_divider"/>
                        <GoFrensMyNfts/>
                    </>
                }

                <div className="gofrens_divider"/>

                <GoFrensTutorial/>

                {fullToken && isNftOwned() || !fullToken
                    ? <>
                        <div className="gofrens_divider"/>
                        {renderOtherCommunities()}
                    </>
                    : null}

            </div>
        </>

    );
}

export default GoFrensPage;